/* ChangePassword.css */

.change-password-container {
    max-width: 500px;
    margin: 50px auto;
    padding: 20px;
    background-color: #f4f7fa;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .title {
    font-size: 24px;
    font-weight: bold;
    color: #5E81F4;
    margin-bottom: 20px;
  }
  
  .yes-button {
    padding: 10px 20px;
    background-color: #5E81F4;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .yes-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .form-container {
    margin-top: 20px;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  .input-field {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border-radius: 5px;
    border: 1px solid #d1d5db;
    font-size: 16px;
    transition: border-color 0.3s ease;
  }
  
  .input-field:focus {
    border-color: #5E81F4;
    outline: none;
  }
  
  .submit-button {
    padding: 10px 20px;
    background-color: #53a8b6;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .submit-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  }
  
  .submit-button.loading {
    background-color: #79c2d0;
    cursor: not-allowed;
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  