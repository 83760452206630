.custom-checkbox {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 2px solid #4ecb71;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
  outline: none;
}

.custom-checkbox:checked {
  background-color: #4ecb71;
  border: none;
}

.custom-checkbox:checked::after {
  content: "";
  position: absolute;
  top: 0.25rem;
  left: 0.5rem;
  width: 0.5rem;
  height: 1rem;
  border: solid white;
  border-width: 0 0.2rem 0.2rem 0;
  transform: rotate(45deg);
}
