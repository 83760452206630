 .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .popup {
    background-color: #fff;
    width: 400px;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: flex-start; 
  }
  
  .popup-content {
    margin-bottom: 20px;
  }
  
  .popup-buttons {
    text-align: right;
    margin-top: auto; 
  }
  
  .popup-buttons button {
    margin-left: 10px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .popup-buttons button:hover {
    background-color: #e5e7eb;
  }
  
  .popup input {
    width: 100%;
    padding: 8px;
    margin-top: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  input:focus,
  input:hover {
    outline: none; 
    border-color: transparent; 
  } 