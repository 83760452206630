.table-container {
    width: 100%;
    margin: auto;
    background-color: #f9fafb; /* Light background */
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table {
    width: 100%;
    border-collapse: collapse;
}

.th {
    background-color: #1f3e74; /* Dark Blue */
    color: white;
    padding: 12px;
    text-align: left;
    font-weight: bold;
}

.td {
    padding: 12px;
    border-bottom: 1px solid #e1e4e8;
}

.tr:nth-child(even) {
    background-color: #f6f8fa; /* Light gray for even rows */
}

.actions {
    display: flex;
    align-items: center;
}
/* 
.actions button {
    margin-right: 8px;
    border: none;
    background: none;
    cursor: pointer;
} */

.actions .edit-icon {
    color: #007bff; /* Edit icon color */
}

.actions .delete-icon {
    color: #ff0000; /* Delete icon color */
}

.toggle-switch {
    margin-left: 16px;
}

.toggle-switch input[type="checkbox"] {
    display: none;
}

.toggle-switch label {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #ccc;
    border-radius: 20px;
    transition: background-color 0.2s;
}

.toggle-switch label::after {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: white;
    border-radius: 50%;
    transition: transform 0.2s;
}

.toggle-switch input[type="checkbox"]:checked + label {
    background-color: #4caf50; /* Switch on color */
}

.toggle-switch input[type="checkbox"]:checked + label::after {
    transform: translateX(20px);
}
