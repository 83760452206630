@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");
:root {
  --baseColor: #656c7c;
  --baseSize: 16px;
  --baseLineHeight: 1.5;
  --fontFamily: Inter, sans-serif;
  --pink: #0C43FF;
  --pinkLight: #0C43FF;
  --blue: #0C43FF;
  --redTick: url("data:image/svg+xml,<svg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M16.552.134 5.717 10.97 1.448 6.701 0 8.149l5.717 5.717L18 1.583 16.552.134Z' fill='%234CBB17'/></svg>%0A");
  --whiteTick: url("data:image/svg+xml,%3Csvg width='18' height='14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.552.134 5.717 10.97 1.448 6.701 0 8.149l5.717 5.717L18 1.583 16.552.134Z' fill='%23FFFFFF'/%3E%3C/svg%3E%0A");
  --close: url("data:image/svg+xml,%3Csvg width='18' height='18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18 1.414 16.586 0 9 7.586 1.414 0 0 1.414 7.586 9 0 16.586 1.414 18 9 10.414 16.586 18 18 16.586 10.414 9 18 1.414Z' fill='%23B1B8C9'/%3E%3C/svg%3E");
  --entpIcon: url("data:image/svg+xml,%3Csvg width='42' height='42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.813 11.077 21 1.155l17.187 9.922v19.846L21 40.845 3.813 30.923V11.077Z' stroke='%230C43FF' stroke-width='2'/%3E%3Ccircle cx='21' cy='21' r='8' stroke='%230C43FF' stroke-width='2'/%3E%3C/svg%3E");;
}
.planItem__container {
  display: flex; /* Enable Flexbox */
  justify-content: space-between; /* Distribute space between cards */
  gap: 1.5rem; /* Optional: Add space between cards */
  flex-wrap: wrap; /* Make cards wrap to the next line if they overflow */
}

.planItem {
  flex: 1 1 30%; /* Adjust this to control card width (30% for 3 cards in a row) */
  /* max-width: 50%; Ensure the cards don’t exceed 30% of the row width */
  /* min-width: 200px; Prevent cards from becoming too narrow on small screens */
}



.plan-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.plan-button {
  padding: 10px 10px;
  background-color: #eef2f7;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
  outline: none;
  text-align: center;
  flex-grow: 1;
  margin: 0 5px;
}

.plan-button:hover {
  background-color: #d7e3f2;
}

.plan-button--active {
  background-color: #0C43FF;
  color: #fff;
  border-color: #0C43FF;
}


body {
  margin: 0;
}
.white{
    color: white;
}
.plans {
  width: 96%;
  max-width: 1128px;
  margin: 0 auto;
}
.plans__container {
  padding: 1rem 0 2rem;
}

.plansHero {
  text-align: center;
  padding: 5rem 0 4.5rem;
  line-height: 1.21;
}
.plansHero__title {
  font-weight: 700;
  font-size: 2rem;
  margin: 0 0 1rem 0;
  color: #000;
}
.plansHero__subtitle {
  margin: 0;
}

.planItem {
  --border: 1px solid #e6e6e6;
  --bgColor: #fff;
  --boxShadow: none;
  background-color: var(--bgColor);
  border: var(--border);
  border-radius: 1rem;
  box-shadow: var(--boxShadow);
  padding: 2rem 1.5rem;
  display: inline-flex;
  flex-direction: column;
}
.planItem__container {
  --direction: column;
  display: grid;
  grid-auto-flow: var(--direction);
  grid-auto-columns: 1fr;
  gap: 1.5rem;
}
.planItem .price {
  --priceMargin: 0.75rem 0;
}
.planItem--pro {
  --border: 0;
  --boxShadow: 0px 14px 30px rgba(204, 204, 204, 0.32);
}
.planItem--pro .label {
  --labelBg: #fdb72e;
  --labelColor: #fff;
}
/* .planItem--entp {
  --bgColor: var(--blue);
} */
/* .planItem--entp .card {
  --titleColor: #fff;
  --descColor: rgb(255 255 255 / 80%);
} */
.planItem--entp .card__icon {
  background-image: var(--entpIcon);
  background-size: cover;
}
/* .planItem--entp .price,
.planItem--entp .featureList {
  --color: #fff;
}
.planItem--entp .featureList {
  --icon: var(--whiteTick); */
/* } */
/* .planItem .button {
  margin-top: auto;
} */

.button {
  --bgColor: var(--pinkLight);
  --color: var(--pink);
  --shadowColor: rgb(234 76 137 / 30%);
  --outline: var(--pink);
  border-radius: 0.5rem;
  display: block;
  width: 100%;
  padding: 13px;
  border: 0;
  line-height: inherit;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 600;
  background-color: var(--bgColor);
  color: var(--color);
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.button--pink {
  --bgColor: var(--pink);
  --color: #fff;
  --shadowColor: rgb(234 76 137 / 50%);
}
.button--white {
  --bgColor: #fff;
  --shadowColor: rgb(255 255 255 / 30%);
  --outline: #fff;
}
.button:hover {
  transform: translateY(-2px);
  box-shadow: 0px 6px 10px var(--shadowColor);
}
.button:focus-visible {
  outline-offset: 2px;
  outline: 2px solid var(--outline);
}

.card {
  --titleColor: #000;
  --descColor: var(--baseColor);
}

.planItem:hover {
  transform: scale(1.05); 
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;/* Scale up slightly on hover */
}
.card__header {
  display: flex;
  gap: 1rem;
  align-items: center;
}
.card__icon {
  width: 2.625rem;
  height: 2.625rem;
}
.card h2 {
  color: var(--titleColor);
  font-size: 1.5rem;
  line-height: 1.2;
  font-weight: 400;
  margin: 0;
  flex-grow: 1;
}
.card__desc {
  margin: 1.5rem 0 0;
  color: var(--descColor);
}

.label {
  --labelColor: var(--baseColor);
  --labelBg: #e5e5e5;
  font-weight: 600;
  line-height: 1.25;
  font-size: 1rem;
  text-align: center;
  padding: 0.625rem 1.125rem;
  border-radius: 2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: var(--labelBg);
  color: var(--labelColor);
}

.price {
  --color: #000;
  --priceMargin: 0;
  display: flex;
  color: var(--color);
  align-items: center;
  gap: 0.5625rem;
  font-weight: 600;
  font-size: 2rem;
  margin: var(--priceMargin);
}
.price .spann {
  font-size: 1rem;
  font-weight: 400;
  color: var(--baseColor);
}

.featureList {
  --color: #000;
  --icon: var(--redTick);
  --height: 0.875rem;
  margin: 0 0 1.75rem;
  padding: 0;
  font-weight: 500;
}
.featureList li {
  color: var(--color);
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
}
.featureList li:before {
  content: "";
  background-image: var(--icon);
  background-size: cover;
  display: block;
  width: 1.125rem;
  height: var(--height);
}
.featureList li:last-child {
  margin-bottom: 0;
}
.featureList li.disabled {
  --color: #b1b8c9;
  --height: 1.125rem;
  --icon: var(--close);
}

.symbol {
  --big: 2.625rem;
  --small: 1.5rem;
  --radius: 0.25rem;
  border: 2px solid var(--blue);
  width: var(--big);
  height: var(--big);
  border-radius: var(--radius);
  position: relative;
}
.symbol--rounded {
  --radius: 2rem;
}
.symbol:after {
  content: "";
  box-sizing: border-box;
  display: block;
  position: absolute;
  border: 2px solid var(--pink);
  width: var(--small);
  height: var(--small);
  border-radius: var(--radius);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width: 640px) {
  .plans {
    max-width: 480px;
    width: 90%;
  }

  .planItem__container {
    --direction: row;
  }
}
@media screen and (min-width: 641px) and (max-width: 768px) {
  :root {
    --baseSize: 12px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1080px) {
  :root {
    --baseSize: 14px;
  }
}
.toggle-container {
  display: flex;
  align-items: center;
  justify-content: end;
  padding: top 10px ;
  padding-right: 20px;
  padding-bottom: 20px;
  margin-top: 20px;
  right: 10px;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #0C43FF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  
}

.toggle-button.active {
  background-color: #4C69D8;
}

.custom{
  color: white;
}
.spanwhite{
  font-size: 1rem;
  font-weight: 400;
}

.toggle, .toggler {
  display: inline-block;
  vertical-align: middle;
  margin: 10px;
}

.toggler {
  color: #ddd;
  transition: .2s;
  font-weight: bold;
}

.toggler--is-active {
  color: #0C43FF;
}

.b {
  display: block;
}

.toggle {
  position: relative;
  width: 80px;
  height: 35px;
  border-radius: 50px;
  background-color: #0C43FF;
  overflow: hidden;
  box-shadow: inset 0 0 2px 1px rgba(0, 0, 0, 0.05);
}

.check {
  position: absolute;
  display: block;
  cursor: pointer;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 6;
}

.check:checked ~ .switch {
  right: 2px;
  left: 57.5%;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: .08s, 0s;
}

.switch {
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 2px;
  right: 57.5%;
  background-color: #fff;
  border-radius: 36px;
  z-index: 1;
  transition: 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transition-property: left, right;
  transition-delay: 0s, .08s;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.card__desc .actual-price {
  text-decoration: line-through;
  color: #888;
  margin-right: 5px;
}

.discount-badge {
  background-color: orange;
  color: white;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 0.8em;
  margin-left: auto;
}

.card__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* In price.css */

.modal-content {
    width: 90%; /* Adjust this as needed */
    max-width: 1200px; /* Increase as necessary */
    padding: 20px;
  }
  