/* Container Styles */
.pod-upload-container {
    padding: 20px;
    font-family: 'Arial', sans-serif;
  }
  
  /* Navigation Tabs */
  .pod-nav {
    display: flex;
    margin-bottom: 20px;
  }
  
  .pod-nav button {
    flex: 1;
    padding: 10px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    cursor: pointer;
    text-align: center;
  }
  
  .pod-nav button.active {
    background-color: #fff;
    border-bottom: 2px solid #5585b5;
    font-weight: bold;
  }
  
  /* POD List */
  .pod-list {
    background-color: #fff;
    border: 1px solid #eaeaea;
    border-radius: 8px;
    padding: 20px;
  }
  
  .pod-title {
    font-size: 24px;
    color: #333;
    margin-bottom: 15px;
    font-weight: 500;
  }
  
  /* POD Item */
  .pod-item {
    padding: 15px;
    border-bottom: 1px solid #eaeaea;
  }
  
  .pod-item:last-child {
    border-bottom: none;
  }
  
  .pod-item p {
    margin: 5px 0;
    font-size: 14px;
  }
  
  .upload-button, .view-button {
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #5585b5;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .upload-button:hover, .view-button:hover {
    background-color: #4678a4;
  }
  
  /* Modal Styles */
  .modal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .modal-content {
    position: relative;
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    max-width: 600px;
    width: 90%;
    text-align: center;
  }
  
  .modal img {
    max-width: 100%;
    height: auto;
  }
  
  .close {
    position: absolute;
    top: 10px;
    right: 15px;
    color: #aaa;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .close:hover,
  .close:focus {
    color: black;
  }
  