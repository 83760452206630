/* General Styles */
.details-container {
  padding: 40px;
  background-color: #f5f6f7;
  min-height: 100vh;
  animation: fadeIn 1s ease-in-out;
}

.section-title {
  font-size: 1.5rem;
  font-weight: 700;
  color: #333;
  margin-bottom: 32px;
  text-align: center;
  border-bottom: 3px solid #e2e2e2;
  padding-bottom: 12px;
  position: relative;
  display: inline-block;
}

.section-title::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: 0;
  width: 60px;
  height: 4px;
  background: #5e81f4;
  transform: translateX(-50%);
}

.status-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  margin-bottom: 40px;
}

.status-box {
  background: #ffffff;
  border: 2px solid #e2e2e2;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 24px;
  text-align: center;
  flex: 1 1 calc(20% - 20px);
  transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
}

.status-box:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
}

.status-box h2 {
  font-size: 1.25rem;
  font-weight: 600;
  color: #555;
  margin-bottom: 10px;
}

.status-box p {
  font-size: 2rem;
  font-weight: 700;
  margin: 0;
}
/* Table Styles */
.table-container table {
  width: 100%;
  border-collapse: collapse;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  animation: fadeIn 1s ease-in-out;
}

.table-container th,
.table-container td {
  padding: 16px;
  text-align: left;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease;
}

.table-container th {
  background-color: #f5f7f9;
  font-size: 1rem;
  font-weight: 700;
  color: #333;
}

.table-container td {
  font-size: 0.875rem;
  color: #555;
}

.table-container thead {
  background: #f5f5f5;
  border-bottom: 2px solid #e2e2e2;
}

.table-container tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.table-container tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.table-container tbody tr:hover {
  background-color: #e2eaff;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.table-container th {
  position: sticky;
  top: 0;
  z-index: 1;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .status-container {
    flex-direction: column;
    align-items: center;
    gap: 12px;
  }

  .status-box {
    flex: 1 1 100%;
  }

  .table-container {
    margin-top: 16px;
  }
}
