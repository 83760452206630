.custompopup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .popupcontent {
    background-color: #3498db; /* Blue background */
    color: #ffffff; /* White text */
    width: 300px; /* Fixed width */
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); /* Shadow for depth */
    position: relative; /* To position close button */
  }
  
  .okbutton {
    background-color: #2ecc71; /* Green button */
    color: #ffffff; /* White text */
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    margin-top: 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .okbutton:hover {
    background-color: #27ae60; /* Darker green on hover */
  }
  
  .closesbutton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 16px;
    color: #ffffff;
    cursor: pointer;
  }
  