.custom-shadows {
  box-shadow: 0 1rem 2rem hsl(0 0% 0% / 20%);

  background: white;
}
.shadoww{
  /* background: red; */
  /* box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px; */
  padding-left: 10px;
  background-color: rgb(239, 246, 247);
  border-radius: 5px;
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px; */
  /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}
@media (min-width: 900px) {
  .shadoww{
  padding-left: 40px;
  padding-right: 40px;
  }
}